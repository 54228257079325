'use client';

import fork from '@haaretz/l-fork.macro';
import NextError from 'next/error';
import React from 'react';
interface GlobalErrorPageProps {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function ServerSideErrorPage({ error, reset: _reset }: GlobalErrorPageProps) {
  // TODO: Design page

  React.useEffect(() => {
    const { href, pathname, host: hostname } = document.location;
    const path = href.slice(href.indexOf(pathname));

    if (!document.location.host.startsWith('local')) {
      const params = new URLSearchParams({
        503: '',
        v2: '',
        path,
        msg: error.message,
      });
      if (error.digest) params.append('digest', error.digest);

      document.location.href = `https://${hostname}/error?${params.toString()}`;
    }
  }, [error.digest, error.message]);

  return (
    <html lang="he">
      {/* eslint-disable-next-line @next/next/no-head-element */}
      <head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.__HTZ = window.__HTZ || {};
            `,
          }}
        />
      </head>
      <body>
        <h1 style={{ color: 'red' }}>Global error</h1>
        <h4>{error.message}</h4>
        <NextError
          statusCode={500}
          title={fork({
            default: 'זה לא אתם זה אנחנו',
            hdc: 'Jerusalem, we have a problem…',
          })}
        />

        <p>
          <ul>
            <li>
              {typeof document !== 'undefined' ? (
                <strong>Path: {document.location.pathname}</strong>
              ) : null}
            </li>
            <li>
              <strong>Message:</strong> {error.message}
            </li>
            <li>
              <strong>Digest:</strong> {error.digest}
            </li>
          </ul>
        </p>
      </body>
    </html>
  );
}
